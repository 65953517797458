.m-banduniform {
	background-color: $color-gray1;
	
	&__in {
		padding-top: 65px;
		padding-bottom: 55px;
		
		@media #{$sp} {
			padding-top: 32px;
			padding-bottom: 28px;
		}
	}
	&__container {
		display: flex;
		
		@media #{$sp} {
			flex-direction: column;
		}
	}
	&__pic {
		width: percentage( 448 / $pc-center );
		padding: 0;
		margin: 0;
		
		@media #{$sp} {
			width: 100%;
		}
		> img {
			display: block;
			width: 100%;
		}
	}
	&__main {
		width: percentage( 377 / $pc-center );
		background-color: $color-black;
		
		@media #{$sp} {
			width: 100%;
		}
		&__ttl {
			margin: 0;
			
			> img {
				width: 100%;
			}
		}
		&__description {
			margin: -60px 0 0 0;
			padding: 0 56px;
			color: $color-white;
			
			@media #{$sp} {
				margin: -16vw 0 0 0;
				padding: 0 24px 32px;
			}
		}
	}
	&__results {
		position: relative;
		width: percentage( 255 / $pc-center );
		padding: 24px 0;
		margin: 0 0 0 auto;
		
		@media #{$sp} {
			width: 100%;
			height: 97vw;
		}
		
		&__container {
			position: absolute;
			left: 0;
			right: 0;
			top: 24px;
			bottom: 24px;
			overflow: hidden;
		}
		&__slide {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}
		&__item {
			margin: 0;
			
			a,
			img {
				display: block;
			}
			img {
				width: 100%;
			}
		}
		&__prev,
		&__next {
			position: absolute;
			left: 0;
			width: 100%;
			height: 24px;
			background-color: $color-black;
			cursor: pointer;
			
			&:after {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 6px;
				height: 6px;
				border-right: 2px solid $color-white;
				border-bottom: 2px solid $color-white;
				content: '';
			}
		}
		&__prev {
			top: 0;
			
			&:after {
				transform: translateX(-50%) translateY(-50%) rotate(-135deg);
			}
		}
		&__next {
			bottom: 0;
			
			&:after {
				transform: rotate(45deg) translateX(-50%) translateY(-50%);
			}
		}
	}
}