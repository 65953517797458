.m-mv {
	position: relative;
	background-color: $color-black;
	
	&__container {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: percentage( 680 / $pc-base );
		overflow: hidden;
	}
	&__slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		/*
		height: auto;
		display: flex;
		*/
	}
	&__item {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		opacity: 0; // default
		// flex: 0 0 auto;
		
		> img {
			display: block;
			width: 100%;
		}
	}
	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		width: 30px;
		height: 30px;
		border-right: 3px solid $color-white;
		border-top: 3px solid $color-white;
		cursor: pointer;
		content: '';
		
		@media #{$sp} {
			width: 12px;
			height: 12px;
			border-right-width: 2px;
			border-top-width: 2px;
		}
	}
	&__prev {
		left: 58px;
		transform: translateY(-50%) rotate(-135deg);
		
		@media #{$sp} {
			left: 18px;
		}
	}
	&__next {
		right: 58px;
		transform: translateY(-50%) rotate(45deg);
		
		@media #{$sp} {
			right: 18px;
		}
	}
}