.m-news {
	background: url(#{$img}home/news-bg.jpg) no-repeat center center;
	background-size: cover;
	
	&__in {
		padding-top: 56px;
		padding-bottom: 56px;
		
		@media #{$sp} {
			padding-top: 28px;
			padding-bottom: 28px;
		}
	}
	&__ttl {
		margin: 0;
		font-size: 2.4rem;
		
		@media #{$sp} {
			font-size: 2.0rem;
		}
	}
	&__container {
		display: flex;
		margin: 16px 0 0 0;
		justify-content: space-between;
		
		@media #{$sp} {
			flex-direction: column;
		}
	}
	&__main {
		width: percentage( 635 / $pc-center );
		
		@media #{$sp} {
			width: 100%;
		}
		> dl {
			display: flex;
			padding: 0;
			margin: 0;
			font-size: 1.5rem;
			
			&:not(:first-child) {
				margin-top: 8px;
			}
			@media #{$sp} {
				font-size: 1.3rem;
			}
			> dt {
				padding: 0;
				margin: 0;
			}
			> dd {
				padding: 0 0 0 1em;
				margin: 0;
				
				a {
					text-decoration: underline;
				}
			}
		}
	}
	&__banners {
		width: percentage( 445 / $pc-center );
		
		@media #{$sp} {
			width: 445px;
			max-width: 100%;
			margin: 24px auto 0;
		}
		> p {
			margin: 0;
			
			&:not(:first-child) {
				margin-top: 32px;
				
				@media #{$sp} {
					margin-top: 16px;
				}
			}
			> a {
				display: block;
			}
		}
	}
}