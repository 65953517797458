@charset "utf-8";

/*===============================================
 header
===============================================*/
.l-header {
	// position: fixed;
	// left: 0;
	// top: 0;
	// z-index: $z-header;
	position: relative;
	width: 100%;
	height: 100px;
	background-color: $color-black;
	
	@media #{$sp} {
		height: 54px;
	}
	
	&__container {
		display: flex;
	}
	&__logo {
		display: flex;
		align-items: center;
		width: 293px;
		height: 100px;
		
		@media #{$sp} {
			width: 172px;
			height: 54px;
		}
		> a {
			display: block;
			
			> img {
				display: block;
			}
		}
	}
	&__gnav {
		margin: 0 0 0 auto;
		
		@media #{$tb-over} {
			display: block !important;
		}
		@media #{$tb} {
			position: absolute;
			left: 0;
			top: 100px;
			z-index: #{$z-header};
			width: 100%;
			background-color: $color-black;
			display: none; // default
		}
		@media #{$sp} {
			top: 54px;
		}
		> ul {
			display: flex;
			padding: 0;
			margin: 0;
			font-size: 1.5rem;
			list-style: none;
			
			@media #{$tb} {
				flex-direction: column;
			}
			> li {
				> a {
					display: flex;
					height: 100px;
					padding: 0 12px;
					align-items: center;
					color: $color-white;
					
					@media #{$tb} {
						display: block;
						height: auto;
						padding: 0;
					}
					> span {
						position: relative;
						display: inline-block;
						padding: 3px 0;
						
						@media #{$tb} {
							display: block;
							padding: 12px;
						}
						&:before {
							position: absolute;
							left: 50%;
							bottom: -2px;
							width: 0%;
							height: 5px;
							background-color: $color-red;
							transition: left .3s $ease-out-cubic, width .3s $ease-out-cubic;
							content: '';
							
							@media #{$tb} {
								display: none;
							}
						}
					}
					&.is-active,
					&:hover {
						opacity: 1;
						color: $color-red;
						
						> span {
							&:before {
								left: 0;
								width: 100%;
							}
						}
					}
					&[data-j-megadrop="parent"] {
						> span {
							padding: 3px 20px 3px 0;
							
							@media #{$tb} {
								padding: 12px 32px 12px 12px;
							}
							&:after {
								position: absolute;
								right: 0;
								top: 50%;
								width: 6px;
								height: 6px;
								border-right: 2px solid $color-white;
								border-bottom: 2px solid $color-white;
								transform: translateY(-50%) rotate(45deg);
								transition: transform .2s $ease-out-cubic;
								content: '';
								
								@media #{$tb} {
									right: 12px;
								}
							}
						}
						&:hover,
						&.is-active {
							> span {
								&:after {
									border-right-color: $color-red;
									border-bottom-color: $color-red;
								}
							}
						}
						&.is-active {
							> span {
								&:after {
									transform: translateY(-20%) rotate(-135deg);
								}
							}
						}
					}
				}
			}
		}
	}
	&__megadrop {
		position: absolute;
		left: 0;
		top: 100px;
		z-index: 1;
		width: 100%;
		background-color: $color-black;
		display: none;
		
		@media #{$tb} {
			position: relative;
			top: 0;
		}
		&__in {
			padding-top: 50px;
			padding-bottom: 50px;
			
			@media #{$tb} {
				max-width: 100% !important;
				padding-left: 12px !important;
				padding-right: 12px !important;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		&__container {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			
			@media #{$tb} {
				display: block;
			}
		}
		&__head {
			width: 160px;
			
			@media #{$tb} {
				display: none;
			}
			> p {
				color: $color-white;
				font-size: 2.4rem;
				font-weight: $fw-bold;
			}
		}
		&__body {
			padding-left: 30px;
			color: $color-white;
			
			@media #{$tb} {
				padding-left: 12px;
			}
			> ul {
				display: flex;
				flex-wrap: wrap;
				padding: 0;
				font-size: 1.6rem;
				line-height: #{(40 / 16)};
				
				@media #{$tb} {
					font-size: 1.3rem;
				}
				> li {
					margin-right: 28px;
					list-style: none;
					
					@media #{$tb} {
						width: 50%;
						margin-right: 0;
					}
					> a {
						color: $color-white;
						
						@media #{$tb} {
							display: block;
							padding: 12px;
						}
					}
				}
			}
		}
	}
	&__cart {
		display: flex;
		width: 50px;
		margin: 0 0 0 20px;
		align-items: center;
		
		@media #{$tb} {
			margin: 0 0 0 auto;
		}
		@media #{$sp} {
			width: 36px;
		}
		> a {
			display: block;
			width: 50px;
			height: 50px;
			background: url(#{$img}common/header-cart.png) no-repeat;
			
			@media #{$sp} {
				width: 36px;
				height: 36px;
				background-size: 100% 100%;
			}
		}
	}
	&__spmenu {
		display: none;
		
		@media #{$tb} {
			position: relative;
			display: flex;
			align-items: center;
			width: 50px;
			margin-left: 16px;
		}
		@media #{$sp} {
			width: 36px;
			margin-left: 8px;
		}
		&__button {
			width: 50px;
			height: 50px;
			padding: 0;
			margin: 0;
			border: 0;
			background: transparent;
			cursor: pointer;
			
			@media #{$sp} {
				width: 36px;
				height: 36px;
			}
		}
		&__icon {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			
			> span {
				position: absolute;
				left: 15%;
				top: 50%;
				width: 70%;
				height: 3px;
				background-color: $color-red;
				transition: opacity .2s, transform .2s ease-out;
				
				@media #{$sp} {
					height: 2px;
				}
				&:nth-child(1) {
					transform: translateY(-2px);
				}
				&:nth-child(2) {
					transform: translateY(-10px);
				}
				&:nth-child(3) {
					transform: translateY(6px);
				}
			}
		}
		&__button.is-active &__icon {
			> span {
				&:nth-child(1) {
					opacity: 0;
				}
				&:nth-child(2) {
					transform: translateY(-2px) rotate(45deg);
				}
				&:nth-child(3) {
					transform: translateY(-2px) rotate(-45deg);
				}
			}
		}
	}
	&__parent {
		> a {
			border-radius: 8px 8px 0 0;
			
			@media #{$tb} {
				border-radius: 0;
			}
			&:after {
				display: inline-block;
				width: 10px;
				height: 7px;
				margin: 0 0 0 6px;
				background: url(#{$img}common/gnav-arw.png) no-repeat;
				content: '';
			}
		}
		/*
		&:hover > a {
			@media #{$tb-over} {
				background-color: #dde5ed;
				color: #0089b6;
				opacity: 1 !important;
			}
		}
		&.is-hover > a {
			background-color: #dde5ed;
			color: #0089b6;
			opacity: 1 !important;
			
			&:after {
				transform: rotate(180deg);
			}
		}
		*/
	}
	&__child {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		top: 100%;
		width: 100%;
		
		@media #{$tb} {
			position: relative;
			top: 0;
		}
	}
	/*
	&__parent:hover &__child {
		@media #{$tb-over} {
			display: block;
		}
	}
	*/
	&__parent.is-hover &__child {
		display: block;
	}
	&__search {
		padding: 18px 0 0 0;
		
		@media #{$tb} {
			padding: 0;
		}
		> a {
			display: block;
			width: 24px;
			height: 24px;
			padding: 0;
			background: url(#{$img}common/gnav-search.png) no-repeat center center;
		}
	}
	&-searchbox {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #dde5ed;
		display: none; // default
		
		.gsc-input-box {
			padding-top: 0;
			padding-bottom: 0;
		}
		
		&__in {
			max-width: 440px;
			margin-left: auto;
			
			@media #{$sp} {
				max-width: none;
				margin-left: 0;
			}
		}
	}
	&-drop {
		width: 100%;
		background-color: #dde5ed;
		
		&__in {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 40px 0;
			
			@media #{$tb} {
				padding: 0 0 spvw(40);
			}
			@media #{$sp} {
				padding: 0 0 40px;
			}
		}
		&__heading {
			// width: 160px;
			width: percentage( 160 / $pc-center );
			flex: 0 0 none;
			
			@media #{$sp} {
				display: none;
			}
		}
		&__ttl {
			&-ja {
				display: block;
				font-size: 24px;
				line-height: 1;
			}
			&-en {
				display: block;
				margin-top: 16px;
				color: #0089b6;
				font-size: 16px;
				line-height: 1;
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			flex: 1 1 auto;
			padding: 0 0 0 40px;
			border-left: 1px solid #ffffff;
			
			@media #{$tb} {
				flex-direction: column;
				padding: 0;
				border-left: 0;
			}
		}
		&__column {
			@media #{$sp} {
				margin-top: 1.6rem;
				
				&:first-child {
					margin-top: 0;
				}
			}
			a {
				color: #0089b6;
			}
			> ul {
				font-size: 15px;
				
				@media #{$tb} {
					display: flex;
					flex-wrap: wrap;
					
					> li {
						margin-right: 2em;
					}
				}
			}
			> dl {
				margin-top: 15px;
				
				&:first-child {
					margin-top: 0;
				}
				dt {
					margin-top: 10px;
					color: #0089b6;
					font-size: 15px;
					font-weight: $fw-medium;
					line-height: 1.5;
					
					&:first-child {
						margin-top: 0;
					}
				}
				dd {
					font-size: 12px;
					line-height: 1.5;
					
					ul {
						margin-top: 10px;
						
						@media #{$tb} {
							display: flex;
							flex-wrap: wrap;
						}
						li {
							margin-top: 4px;
							padding-left: 10px;
							background: url(#{$img}common/fnav-arw.png) no-repeat left 5px;
							
							&:first-child {
								margin-top: 0;
							}
							
							@media #{$sp} {
								margin-right: 2em;
								
								&:first-child {
									margin-top: 4px;
								}
							}
						}
					}
				}
			}
		}
		&-group {
			position: relative;
			flex: 1 1 auto;
			margin: 0 10px;
			
			&__ttl {
				color: #0089b6;
				font-size: 15px;
				font-weight: $fw-medium;
			}
			&__in {
				display: flex;
				justify-content: space-between;
				margin-top: 5px;
				padding-top: 10px;
				border-top: 1px solid #ffffff;
				
				@media #{$sp} {
					flex-direction: column;
				}
			}
		}
		
		&--company &__body &__column {
			width: percentage( 0.9 / 3 );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		
		&--products &__body &__column {
			width: percentage( 0.9 / 3 );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		
		&--recruit &__body &__column {
			width: percentage( 0.9 / 2 );
			
			@media #{$sp} {
				width: 100%;
			}
		}
		
		&--download &__body &__column {
			width: percentage( 0.9 / 2 );
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
}
