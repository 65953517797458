.m-btn {
	&01 {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 230px;
		min-height: 100%;
		padding: 1em;
		background-color: $color-red;
		color: $color-white;
		font-size: 1.8rem;
		font-weight: $fw-bold;
		line-height: 1.2;
		transition: border-color .2s, background-color .2s, color .2s;
		
		@media #{$sp} {
			min-width: 18rem;
			font-size: 1.5rem;
		}
		&[href]:hover {
			opacity: 1;
		}
		&:hover {
			background-color: #ca3175;
		}
		&--small {
			min-width: 120px;
			padding: 0.8em;
			font-size: 1.4rem;
			
			@media #{$sp} {
				min-width: 9rem;
				font-size: 1.2rem;
			}
		}
		&--arw {
			> span {
				position: relative;
				padding-right: 1em;
				
				&:after {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-40%) rotate(45deg);
					width: 7px;
					height: 7px;
					border-top: 2px solid $color-white;
					border-right: 2px solid $color-white;
					content: '';
				}
			}
			&:hover {
				> span {
					&:after {
						border-color: #fff;
					}
				}
			}
		}
		&--arw-reverse {
			> span {
				position: relative;
				padding-left: 1em;
				
				&:before {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-40%) rotate(-45deg);
					width: 7px;
					height: 7px;
					border-top: 2px solid $color-white;
					border-left: 2px solid $color-white;
					content: '';
				}
			}
			&:hover {
				> span {
					&:before {
						border-color: #fff;
					}
				}
			}
		}
		&--small {
			> span {
				&:after {
					transform: translateY(-40%) rotate(45deg);
					width: 5px;
					height: 5px;
				}
			}
		}
		&--full {
			width: 100%;
			min-width: 0;
			box-sizing: border-box;
		}
		&--noborder {
			background-color: #ffffff;
			border: 0;
		}
	}
	&02 {
		display: block;
		padding: 0.75em 1em;
		border: 0;
		background-color: $color-red;
		text-align: center;
		color: #ffffff;
		font-size: 2.4rem;
		font-family: $ff-default;
		font-weight: $fw-normal;
		line-height: 1.5;
		transition: background-color .2s, color .2s;
		
		@media #{$sp} {
			font-size: 1.8rem;
			font-weight: $fw-bold;
		}
		&[href]:hover {
			opacity: 1;
		}
		&:hover {
			background-color: #ca3175;
		}
		
		&--small {
			font-size: 1.8rem;
			
			@media #{$sp} {
				font-size: 1.4rem;
			}
		}
	}
}

button.m-btn01,
button.m-btn02 {
	cursor: pointer;
	box-sizing: content-box;
}
button.m-btn01--full {
	box-sizing: border-box;
}