@charset "utf-8";

/*===============================================
 footer
===============================================*/
.l-footer {
	&__nav {
		background-color: $color-gray1;
		
		&__in {
			padding-top: 40px;
			padding-bottom: 40px;
			
			@media #{$sp} {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
		&__container {
			display: flex;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&__column {
			width: percentage( 255 / $pc-center );
			color: $color-white;
			
			@media #{$sp} {
				width: 100%;
			}
			a {
				color: $color-white;
			}
			p {
				&:not(:first-child) {
					margin-top: 12px;
				}
			}
			ul {
				padding: 0 0 0 12px;
				margin: 0;
				
				li {
					list-style: none;
					
					> a {
						position: relative;
						display: inline-block;
						padding-left: 15px;
						
						&:before {
							position: absolute;
							left: 0;
							top: 50%;
							width: 6px;
							height: 6px;
							border-right: 2px solid $color-white;
							border-top: 2px solid $color-white;
							transform: translateY(-50%) rotate(45deg);
							content: '';
						}
					}
				}
			}
		}
	}
	&__copyright {
		background-color: $color-black;
		
		&__in {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		
		p {
			text-align: center;
			color: $color-white;
			font-size: 1.5rem;
			line-height: 1;
			
			@media #{$sp} {
				font-size: 1.3rem;
			}
		}
	}
}
