@charset "utf-8";

/*===============================================
 home
===============================================*/
.p-home {
	&-kv {
		&__slider {
			position: relative;
			width: 100%;
		}
		&__container {
			position: relative;
			width: 100%;
			padding-top: percentage( 400 / 1280 );
			background: url(#{$img}home/loading.gif) no-repeat center center;
			
			@media #{$sp} {
				background-size: 32px 32px;
			}
		}
		&__item {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			overflow: hidden;
			opacity: 0; // default
			
			> img,
			> video {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 100%;
				height: auto;
				transform: translateX(-50%) translateY(-50%);
			}
		}
		&__in {
			position: relative;
		}
		&__catch {
			text-align: center;
			color: #fff;
			font-size: 3.8rem;
			font-family: $ff-catch;
			font-weight: $fw-bold;
			line-height: 1.5;
			text-shadow: 0 0 6px #000;
			
			@media #{$tb} {
				font-size: spvw(16);
			}
		}
		&__link {
			margin-top: 32px;
			text-align: center;
			
			@media #{$sp} {
				margin-top: spvw(12);
			}
			> a {
				display: inline-block;
				padding: 0.75em 1em;
				background-color: #fff;
				box-shadow: 0 0 6px rgba(0,0,0,0.1);
				color: #0089b6;
				font-size: 1.6rem;
				line-height: 1;
				letter-spacing: 0.05em;
				transition: border-color .2s, background-color .2s, color .2s;
				
				@media #{$sp} {
					font-size: spvw(12);
				}
				&:before {
					display: inline-block;
					width: 0.45em;
					height: 0.45em;
					border-top: 2px solid #0089b6;
					border-right: 2px solid #0089b6;
					content: '';
					margin-right: 0.5em;
					vertical-align: 0;
					transform: rotate(45deg);
					content: '';
					transition: border-color .2s;
				}
				&:hover {
					opacity: 1;
					border-color: #00bab3;
					background-color: #00bab3;
					color: #fff;
					
					&:before {
						border-top-color: #ffffff;
						border-right-color: #ffffff;
					}
				}
			}
		}
		&__nav {
			position: absolute;
			left: 50%;
			bottom: 20px;
			z-index: 1;
			display: flex;
			transform: translateX(-50%);
			
			@media #{$sp} {
				left: auto;
				right: spvw(8);
				bottom: spvw(8);
				transform: none;
			}
			> li {
				width: 22px;
				height: 6px;
				margin: 0 3px;
				background-color: #ffffff;
				box-shadow: 0 0 3px rgba(0,0,0,0.5);
				transition: background-color .2s linear;
				cursor: pointer;
				
				@media #{$sp} {
					width: spvw(8);
					height: spvw(3);
					margin: 0 spvw(2);
				}
				
				&.is-active {
					background-color: #0089b6;
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
	&-news {
		&__in {
			padding-top: 36px;
			padding-bottom: 45px;
		}
		&__ttl {
			color: #333333;
			font-size: 1.8rem;
			font-weight: $fw-bold;
			line-height: 1;
		}
		&__list {
			margin-top: 16px;
		}
		&-item {
			display: flex;
			padding: 12px 0;
			
			@media #{$sp} {
				flex-wrap: wrap;
			}
			&__date {
				padding-right: 16px;
				font-size: 1.4rem;
				line-height: #{(20 / 14)};
				white-space: nowrap;
			}
			&__cat {
				display: flex;
				width: 60px;
				height: 20px;
				border-radius: 10px;
				background-color: #0089b6;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-size: 1.1rem;
				line-height: 1;
				white-space: nowrap;
			}
			&__ttl {
				padding-left: 11px;
				font-size: 1.4rem;
				font-weight: $fw-normal;
				line-height: #{(20 / 14)};
				
				@media #{$sp} {
					width: 100%;
					padding-left: 0;
					padding-top: 5px;
				}
				> a {
					color: #0089b6;
				}
			}
		}
		&__links {
			margin-top: 20px;
			text-align: right;
			font-size: 1.4rem;
			line-height: #{(20 / 14)};
			
			> a {
				margin-left: 2em;
				color: #0089b6;
				
				&:first-child {
					margin-left: 0;
				}
				&:before {
					display: inline-block;
					width: 8px;
					height: 13px;
					margin-right: 5px;
					vertical-align: -1px;
					background: url(#{$img}common/link-arw.png) no-repeat center center;
					content: '';
				}
			}
		}
	}
	&-company {
		background: url(#{$img}home/company-bg.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 56px;
			padding-bottom: 48px;
		}
		&__list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&-item {
			width: percentage( 470 / $pc-center );
			margin-top: 40px;
			margin-left: percentage( ($pc-center - 470 * 2) / $pc-center );
			
			&:nth-child(-n+2) {
				margin-top: 0;
			}
			&:nth-child(2n+1) {
				margin-left: 0;
			}
			@media #{$sp} {
				width: 100%;
				margin-top: 20px;
				margin-left: 0;
				
				&:nth-child(-n+2) {
					margin-top: 20px;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			
			a {
				position: relative;
				display: block;
				
				&:hover {
					opacity: 1 !important;
				}
			}
			&__thumb {
				position: relative;
				display: block;
				width: 100%;
				background-color: #fff;
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					// transition: transform .8s $ease-out-cubic, opacity .2s;
				}
			}
			/*
			a:hover &__thumb {
				> img {
					transform: scale(1.05);
					opacity: 0.75;
				}
			}
			*/
			&__ttl {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding-bottom: 16px;
				text-align: center;
				color: #ffffff;
				font-size: 2.0rem;
				font-weight: $fw-bold;
				line-height: 1;
				transition: bottom .4s $ease-out-cubic;
			}
			a:hover &__ttl {
				bottom: 1rem;
			}
		}
	}
	&-products {
		background: url(#{$img}home/products-bg.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		&__list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&-item {
			width: percentage( 300 / $pc-center );
			margin-top: 30px;
			margin-left: percentage( ($pc-center - 300 * 3) / 2 / $pc-center );
			
			&:nth-child(-n+3) {
				margin-top: 0;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			@media #{$sp} {
				width: 49%;
				margin-top: 2%;
				margin-left: 2%;
				
				&:nth-child(-n+3) {
					margin-top: 2%;
				}
				&:nth-child(3n+1) {
					margin-left: 2%;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
				}
				&:nth-child(2n+1) {
					margin-left: 0;
				}
			}
			
			> a,
			> div {
				position: relative;
				display: block;
				
				&:hover {
					opacity: 1 !important;
				}
			}
			&__thumb {
				position: relative;
				display: block;
				width: 100%;
				background-color: #fff;
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					// transition: transform .8s $ease-out-cubic, opacity .2s;
				}
			}
			/*
			a:hover &__thumb {
				> img {
					transform: scale(1.05);
					opacity: 0.75;
				}
			}
			*/
			&__ttl {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding-bottom: 16px;
				text-align: center;
				color: #ffffff;
				font-size: 2.0rem;
				font-weight: $fw-bold;
				line-height: 1;
				text-shadow: 0 1px 10px #005d95;
				transition: bottom .4s $ease-out-cubic;
				
				@media #{$sp} {
					font-size: 1.6rem;
				}
				> small {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 140px;
					height: 28px;
					margin: 8px auto 0;
					border: 1px solid #ffffff;
					border-radius: 14px;
					color: #ffffff;
					font-size: 1.2rem;
					font-weight: $fw-normal;
					line-height: 1;
					
					@media #{$sp} {
						width: 120px;
						height: 24px;
						border-radius: 12px;
						font-size: 1.1rem;
					}
				}
			}
			a:hover &__ttl {
				bottom: 1rem;
			}
		}
	}
	&-recruit {
		background: linear-gradient( #8cd8d5 0%, #ffffff 100% );
		
		&__in {
			padding-top: 56px;
			padding-bottom: 64px;
		}
		&__catch {
			margin-bottom: 16px;
			text-align: center;
			font-size: 2.0rem;
			font-weight: $fw-bold;
			line-height: 1.5;
			
			@media #{$sp} {
				font-size: 1.6rem;
			}
		}
		&__lead {
			margin-bottom: 56px;
			text-align: center;
			font-size: 1.4rem;
			line-height: 2;
			
			@media #{$sp} {
				text-align: left;
				font-size: 1.2rem;
			}
		}
		&__banners {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		&-item {
			width: percentage( 470 / $pc-center );
			margin-left: percentage( ($pc-center - 470 * 2) / $pc-center );
			
			&:nth-child(2n+1) {
				margin-left: 0;
			}
			
			@media #{$sp} {
				width: 100%;
				margin-left: 0;
				margin-top: 10px;
				
				&:first-child {
					margin-top: 0;
				}
			}
			/*
			a {
				position: relative;
				display: block;
				
				&:hover {
					opacity: 1 !important;
				}
			}
			*/
			&__thumb {
				/*
				position: relative;
				display: block;
				width: 100%;
				background-color: #fff;
				overflow: hidden;
				
				> img {
					display: block;
					width: 100%;
					transition: transform .8s $ease-out-cubic, opacity .2s;
				}
				*/
			}
			&__ttl {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				color: $color-black;
				font-size: 2.2rem;
				font-family: $ff-en;
				font-weight: $fw-bold;
				text-shadow: 0 2px 4px rgba(255,0,0,0.5);
				
				@media #{$sp} {
					font-size: 2.0rem;
				}
			}
			/*
			a:hover &__thumb {
				> img {
					transform: scale(1.05);
					opacity: 0.75;
				}
			}
			*/
		}
		&__links {
			margin-top: 40px;
			display: flex;
			// justify-content: flex-start;
			justify-content: center;
			flex-wrap: wrap;
		}
		&-link {
			width: percentage( 230 / $pc-center );
			margin-left: percentage( ($pc-center - 230 * 4) / 3 / $pc-center );
			
			&:nth-child(4n+1) {
				margin-left: 0;
			}
			
			@media #{$sp} {
				width: 49%;
				margin-left: 2%;
				margin-top: 2%;
				
				&:nth-child(4n+1) {
					margin-left: 2%;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
				}
				&:nth-child(2n+1) {
					margin-left: 0;
				}
			}
		}
		&__entry {
			margin-top: 30px;
		}
	}
}