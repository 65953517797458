.p-single {
	background-color: $color-black;
	
	&__mv {
		picture,
		img {
			display: block;
			width: 100%;
		}
	}
	&__heading {
		background: url(#{$img}common/bg_all.png) repeat left top;
		
		&__in {
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
	&__plist {
		background-color: rgba(255,255,255,0.1);
		
		&__in {
			padding-top: 4px;
			padding-bottom: 4px;
		}
		&__scroll {
			overflow-x: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			
			&::-webkit-scrollbar{
				display: none;
			}
		}
		ul {
			display: flex;
			margin: 0;
			padding: 0;
			
			li {
				position: relative;
				padding-left: 0;
				margin-left: 0;
				list-style: none;
				
				&:not(:first-child) {
					padding-left: 2em;
					
					&:before {
						position: absolute;
						left: 0.5em;
						top: 50%;
						display: block;
						width: 0.5em;
						height: 0.5em;
						border-right: 1px solid #ddd;
						border-top: 1px solid #ddd;
						transform: rotate(45deg) translateY(-50%);
						content: '';
					}
				}
				a,
				span {
					color: #ddd;
				}
			}
		}
	}
	&__ttl {
		margin: 0;
		color: $color-red;
		font-size: 3.8rem;
	}
	&__body {
		padding-top: 60px;
		padding-bottom: 120px;
		color: $color-white;
		
		h2,
		h3,
		h4,
		h5,
		p {
			margin: 0;
			
			&:not(:first-child) {
				margin-top: 1.5em;
			}
		}
		h2 {
			font-size: 2.8rem;
		}
		h3 {
			font-size: 2.2rem;
		}
		h4 {
			font-size: 1.8rem;
		}
		h5 {
			font-size: 1.4rem;
			font-weight: $fw-bold;
		}
		p {
			font-size: 1.4rem;
		}
	}
}