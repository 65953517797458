.m-mediatext {
	display: flex;
	justify-content: space-between;
	
	&--left {
		flex-direction: row-reverse;
	}
	
	&__img {
		width: calc( 100% * 480 / 1110 );
		margin: 0;
		
		picture,
		img {
			display: block;
			width: 100%;
		}
	}
	&__txt {
		width: calc( 100% * ( 1110 - 480 - 40 ) / 1110 );
	}
}