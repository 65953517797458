.m-margin {
	@for $i from 1 through 20 {
		&-top-#{$i} { margin-top: 1rem * $i; }
	}
	@for $i from 1 through 20 {
		&-bottom-#{$i} { margin-bottom: 1rem * $i; }
	}
}

.m-margin {
	@for $i from 1 through 20 {
		&-top-sp-#{$i} {
			@media #{$sp} {
				margin-top: 1rem * $i !important;
			}
		}
	}
	@for $i from 1 through 20 {
		&-bottom-sp-#{$i} {
			@media #{$sp} {
				margin-bottom: 1rem * $i !important;
			}
		}
	}
}