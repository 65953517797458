.m-inquiry {
	background-color: $color-black;
	
	&__in {
		padding-top: 62px;
		padding-bottom: 62px;
		
		@media #{$sp} {
			padding-top: 0;
			padding-bottom: 32px;
		}
	}
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 540px;
		height: 80px;
		margin: 0 auto;
		background-color: $color-white;
		font-size: 1.8rem;
		font-weight: $fw-bold;
		
		@media #{$sp} {
			height: 64px;
			font-size: 1.5rem;
		}
		> span {
			position: relative;
			display: inline-block;
			padding: 0 28px 0 0;
			
			@media #{$sp} {
				padding: 0 16px 0 0;
			}
			&:after {
				position: absolute;
				right: 0;
				top: 50%;
				width: 8px;
				height: 8px;
				border-right: 2px solid $color-black;
				border-top: 2px solid $color-black;
				transform: rotate(45deg) translateY(-50%);
				content: '';
				
				@media #{$sp} {
					width: 6px;
					height: 6px;
				}
			}
		}
	}
}