@charset "utf-8";

//-----------------------------------------------
// responsive setting
//-----------------------------------------------
$sp-base: 375;
$pc-base: 1366;
$pc-center: 1110;

$large: "only screen and (min-width: 1281px)";
$tb: "only screen and (max-width: 1024px)";
$tb-over: "only screen and (min-width: 1025px)";
$sp: "only screen and (max-width: 768px)";
$sp-over: "only screen and (min-width: 769px)";