.m-gallery {
	background-color: $color-black;
	
	&__in {
		padding-top: 65px;
		padding-bottom: 80px;
		
		@media #{$sp} {
			padding-top: 32px;
			padding-bottom: 40px;
		}
	}
	&__container {
		position: relative;
		width: 100%;
		height: 350px;
		overflow: hidden;
		
		@media #{$sp} {
			height: 160px;
		}
	}
	&__slide {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
	}
	&__item {
		width: 350px;
		padding: 0 14px;
		
		@media #{$sp} {
			width: 160px;
		}
	}
}