.m-uniform {
	background: url(#{$img}home/uniform-bg.jpg) no-repeat center center;
	background-size: cover;
	
	&__in {
		padding-top: 80px;
		padding-bottom: 60px;
		
		@media #{$sp} {
			padding-top: 40px;
			padding-bottom: 30px;
		}
	}
	&__ttl {
		margin: 0;
		text-align: center;
		color: $color-white;
		font-size: 2.8rem;
		font-weight: $fw-medium;
		letter-spacing: 0.05em;
		
		@media #{$sp} {
			font-size: 2.0rem;
		}
	}
	&__container {
		display: flex;
		justify-content: space-between;
		margin-top: 72px;
		
		@media #{$tb} {
			flex-wrap: wrap;
		}
		@media #{$sp} {
			margin-top: 24px;
		}
	}
	&__item {
		position: relative;
		width: percentage( 352 / $pc-center );
		overflow: hidden;
		
		@media #{$tb} {
			width: 48%;
			
			&:nth-child(n+3) {
				margin-top: 4%;
			}
		}
		@media #{$sp} {
			width: 100%;
			
			&:nth-child(n+2) {
				margin-top: 4%;
			}
		}
		&__link {
			display: block;
			
			&:hover {
				opacity: 1 !important;
			}
		}
		&__pic {
			padding: 0;
			margin: 0;
			
			> img {
				display: block;
				width: 100%;
			}
		}
		&__overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			height: auto;
			min-height: 45%;
			padding: 32px 24px;
			box-sizing: border-box;
			transition: min-height .3s $ease-out-cubic;
			background-color: rgba(0,0,0,0.7);
			color: $color-white;
			
			@media #{$sp} {
				min-height: 40%;
				padding: 24px 16px;
			}
		}
		&:hover &__overlay {
			min-height: 100%;
		}
		&__ttl {
			margin: 0;
			font-size: 1.8rem;
			
			@media #{$sp} {
				font-size: 1.6rem;
			}
		}
		&__description {
			font-size: 1.5rem;
			
			@media #{$sp} {
				font-size: 1.3rem;
			}
		}
		&__detail {
			display: none;
		}
		&:hover &__detail {
			display: block;
			margin: 30px 0 0 0;
			text-align: center;
			font-size: 1.5rem;
			
			> span {
				position: relative;
				display: inline-block;
				
				&:after {
					position: absolute;
					left: -2px;
					bottom: -2px;
					display: block;
					width: 137px;
					height: 17px;
					background: url(#{$img}home/uniform-arw.png) no-repeat;
					pointer-events: none;
					content: '';
				}
			}
		}
	}
}